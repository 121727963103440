import cn from 'classnames';
import { Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import style from './style';
import tabsConfig from './tabs/config';

const Styled = styled.div`
  ${style}
`;

export const AdminSKUEditor = ({ updateQuery, createQuery, deleteQuery, context }) => {
  const { values, mode } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { detailType } = context;
  const navigate = useNavigate();

  const { DETAIL_TYPES } = Form;

  const onFinally = React.useCallback(() => {
    const behaviors = {
      [DETAIL_TYPES.VIEW]: () => navigate(`/admin-skus`), // TODO: Switch to edit mode and stay put.
      [DETAIL_TYPES.DRAWER]: closeDrawer,
      [DETAIL_TYPES.FULL_WIDTH_DRAWER]: closeDrawer,
      [DETAIL_TYPES.MODAL]: () => {},
    };
    behaviors[detailType]?.();
  }, [
    DETAIL_TYPES.DRAWER,
    DETAIL_TYPES.FULL_WIDTH_DRAWER,
    DETAIL_TYPES.MODAL,
    DETAIL_TYPES.VIEW,
    closeDrawer,
    detailType,
    navigate,
  ]);

  return (
    <Styled className={cn('admin-sku-editor')}>
      <Typography.H2>
        {mode === 'edit' ? (
          values?.name
        ) : (
          <Localization.Translate stringId="createAdminSKU" data-testid="create-admin-sku-header" />
        )}
      </Typography.H2>
      <hr />
      {/*Props, other than config, are passed to each underlying tab component.*/}
      <Form.Tabs config={tabsConfig} createQuery={createQuery} updateQuery={updateQuery} onFinally={onFinally} />
    </Styled>
  );
};
