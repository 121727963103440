import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import adminSKUs from '../admin-skus';

export const useListAdminSKUs = () => {
  return useQuery({
    queryKey: ['admin-skus'],
    queryFn: () => adminSKUs.listAdminSKUs(),
    staleTime: Infinity,
  });
};

export const useCreateAdminSKU = () => {
  const queryClient = useQueryClient();

  const queryKey = ['admin-skus'];

  const mutation = useMutation({
    mutationFn: adminSKUs.createAdminSKU,
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useUpdateAdminSKU = () => {
  const queryClient = useQueryClient();

  const queryKey = ['admin-skus'];

  const mutation = useMutation({
    mutationFn: adminSKUs.updateAdminSKU,
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useDeleteAdminSKU = () => {
  const queryClient = useQueryClient();

  const queryKey = ['admin-skus'];

  const mutation = useMutation({
    mutationFn: adminSKUs.deleteAdminSKU,
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useGetAdminSKU = ({ skuRef }) => {
  return useQuery({
    queryKey: ['admin-skus', skuRef],
    queryFn: () => adminSKUs.getAdminSKU({ skuRef }),
    staleTime: Infinity,
  });
};
