import { Form } from 'connex-cds';
import { pick } from 'lodash';
import React from 'react';

export const General = ({ updateQuery, createQuery, deleteQuery, onFinally }) => {
  const { Components } = Form.useFormContext();

  const { SaveButton, CancelButton, DeleteButton } = Components;

  const handleUpdate = React.useCallback(
    entity => {
      const pickedEntity = {
        ...pick(entity, ['name', 'crn', 'status', 'timeZone']),
        isTestEntity: entity?.isTestEntity || false,
      };
      return updateQuery?.(pickedEntity);
    },
    [updateQuery]
  );

  const handleCreate = React.useCallback(
    entity => {
      const pickedEntity = {
        ...pick(entity, ['name', 'crn', 'status', 'timeZone']),
        isTestEntity: entity?.isTestEntity || false,
      };
      return createQuery?.(pickedEntity);
    },
    [createQuery]
  );

  const handleDelete = React.useCallback(() => {}, []);

  return (
    <div>
      <Components.Name />
      <Components.Crn />
      <Components.TimeZone />
      <Components.Status />
      <Components.IsTestEntity />
      <div className="buttons">
        <div className="left">
          {deleteQuery ? <DeleteButton onDelete={handleDelete} onFinally={onFinally} /> : null}
        </div>
        <div className="right">
          <CancelButton onCancel={onFinally} enabled />
          <SaveButton onCreate={handleCreate} onUpdate={handleUpdate} onFinally={onFinally} />
        </div>
      </div>
    </div>
  );
};
