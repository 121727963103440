import cn from 'classnames';
import { find, sortBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import defaultMenuLogo from '../../../assets/cai_logo_2c_white.svg';
import { MaterialIcon } from '../../../components/icons';
import { Translate, useTranslateMessage } from '../../../components/localization';
import { useAppData } from '../../../util/app-data/AppData';
import { useAppContext } from '../../../util/app-provider/context';

import { useEntityContext } from '../../../util/entity-provider/context';
import { isProduction } from '../../../util/isProduction';

import { SidebarContainer } from './styles';

const SORT_VALUES = [
  { iconName: 'SortByAlpha', sortKey: 'alpha' },
  { iconName: 'FormatListNumbered', sortKey: 'default' },
];

const NewSidebar = () => {
  const translateMessage = useTranslateMessage();
  const [activatedSubMenu, setActivatedSubMenu] = useState(null);
  const [hideSortableSubMenu, setHideSortableSubMenu] = useState(false);

  const { appConfig } = useAppContext();
  const [sortMode, setSortMode] = useState('default');
  const { entityRef } = useEntityContext();
  const { menuConfig: initialMenuConfig } = useAppData();
  const { menuConfig, setCurrentFeature } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  const preparedConfig = useMemo(() => {
    if (!menuConfig) {
      return [];
    }
    const isProd = isProduction();

    return menuConfig
      ?.map?.(configItem => configItem || undefined)
      ?.filter?.(configItem => (isProd ? !configItem.hideInProduction : true))
      ?.map?.(configItem => {
        if (configItem?.children?.length) {
          return {
            ...configItem,
            children: configItem.children.map(child => ({
              ...child,
              path: `${configItem.path}${child.path}`,
            })),
          };
        }
        return configItem;
      });
  }, [menuConfig]);

  const determineCurrentFeature = React.useCallback(() => {
    const flattenedMenuConfig = menuConfig?.reduce?.((acc, item) => {
      if (item?.children?.length) {
        return [...acc, ...item.children.map(child => ({ ...child, path: `${item.path}${child.path}` }))];
      }
      return [...acc, item];
    }, []);

    const matched = matchPath(
      { path: `${appConfig?.isEntitySpecific ? '/:entityRef' : ''}/:path/*` },
      location.pathname
    );

    const targetPath =
      `${matched?.params?.path || ''}${matched?.params?.['*'] ? `/${matched?.params?.['*']}` : ''}` || '';

    const targetItem = find(flattenedMenuConfig, { path: `/${targetPath}` });
    setCurrentFeature(targetItem);
  }, [appConfig?.isEntitySpecific, location, menuConfig, setCurrentFeature]);

  React.useEffect(() => {
    determineCurrentFeature();
  }, [determineCurrentFeature]);

  const handleMenuItemClick = useCallback(
    menuItemPath => {
      const destination = `${appConfig?.isEntitySpecific ? `/${entityRef}` : ''}${menuItemPath}`;
      if (destination !== location.pathname) navigate(destination);
    },
    [appConfig?.isEntitySpecific, entityRef, location.pathname, navigate]
  );

  const activateSubMenu = option => {
    if (option?.children?.length) {
      setActivatedSubMenu(option);
    }
  };

  const clearActivatedSubMenu = () => {
    setActivatedSubMenu(null);
  };

  const amIActive = React.useCallback(
    me => location.pathname.startsWith(`${appConfig?.isEntitySpecific ? `/${entityRef}` : ''}${me.path}`),
    [appConfig?.isEntitySpecific, entityRef, location.pathname]
  );

  const sortedChildren = React.useMemo(() => {
    if (sortMode === 'default') {
      return activatedSubMenu?.children;
    }
    return sortBy(activatedSubMenu?.children || [], [
      function (o) {
        return translateMessage(o.labelStringId);
      },
    ]);
  }, [activatedSubMenu?.children, sortMode, translateMessage]);

  return (
    <SidebarContainer className={cn('sidebar', { 'has-children': activatedSubMenu?.children })}>
      <div className="sidebar-logo-container">
        <img src={initialMenuConfig?.menuLogo || defaultMenuLogo} alt="menu-logo" />
      </div>
      <div className="sidebar-options-container">
        {preparedConfig.map((option, index) => {
          const hasChildren = option?.children?.length;

          return (
            <div
              key={index}
              className={cn('option', {
                option__active: amIActive(option),
              })}
              onClick={
                hasChildren
                  ? undefined
                  : () => {
                      handleMenuItemClick(option.path);
                    }
              }
              onMouseOver={
                hasChildren
                  ? () => {
                      clearActivatedSubMenu();
                      activateSubMenu(option);
                      setHideSortableSubMenu(option?.hideSortControls);
                    }
                  : undefined
              }
            >
              {option?.iconName ? <MaterialIcon iconName={option?.iconName} className="icon" /> : option.icon}
            </div>
          );
        })}
      </div>
      {activatedSubMenu ? (
        <div className="menu-sidebar" onMouseLeave={() => clearActivatedSubMenu()}>
          <div className="menu-title-container">
            {activatedSubMenu.iconName ? <MaterialIcon iconName={activatedSubMenu?.iconName} /> : activatedSubMenu.icon}
            <Translate stringId={activatedSubMenu?.labelStringId} />
          </div>
          {!hideSortableSubMenu ? (
            <div className="menu-order-container">
              {SORT_VALUES.map(sortValue => (
                <div
                  key={sortValue.sortKey}
                  className={cn('menu-order-icon', { 'menu-order-icon__active': sortMode === sortValue.sortKey })}
                  onClick={() => setSortMode(sortValue.sortKey)}
                >
                  <MaterialIcon iconName={sortValue?.iconName} fontSize="small" />
                </div>
              ))}
            </div>
          ) : null}
          <div className="menu-options-container">
            {sortedChildren.map((subMenuItem, index) => {
              return (
                <div
                  key={index}
                  className={cn('menu-option', { 'menu-option__active': amIActive(subMenuItem) })}
                  onClick={() => {
                    handleMenuItemClick(subMenuItem.path);
                    clearActivatedSubMenu();
                  }}
                >
                  <Translate stringId={subMenuItem?.labelStringId} />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </SidebarContainer>
  );
};

export default NewSidebar;
