import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import entities from '../entities';

export const useListEntities = () => {
  return useQuery({
    queryKey: ['entities'],
    queryFn: () => entities.listEntities(),
    staleTime: Infinity,
  });
};

export const useCreateEntity = () => {
  const queryClient = useQueryClient();

  const queryKey = ['entities'];

  const mutation = useMutation({
    mutationFn: entities.createEntity,
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useUpdateEntity = () => {
  const queryClient = useQueryClient();

  const queryKey = ['entities'];

  const mutation = useMutation({
    mutationFn: entities.updateEntity,
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (_err, _newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useDeleteEntity = () => {
  const queryClient = useQueryClient();

  const queryKey = ['entities'];

  const mutation = useMutation({
    mutationFn: appRef => entities.deleteEntity({ appRef }),
    onMutate: async ({ appRef }) => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.filter?.(item => item.crn !== appRef);
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, appRef };
    },
    onError: (_err, _appRef, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};
