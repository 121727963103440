import { Form } from 'connex-cds';
import React from 'react';

export const General = ({ updateQuery, createQuery, deleteQuery, onFinally }) => {
  const { Components } = Form.useFormContext();

  const { SaveButton, CancelButton, DeleteButton } = Components;

  const handleUpdate = React.useCallback(
    app => {
      return updateQuery?.(app)?.then(response => {
        onFinally?.();
        return response;
      });
    },
    [onFinally, updateQuery]
  );

  const handleCreate = React.useCallback(() => {}, []);

  const handleDelete = React.useCallback(() => {}, []);

  return (
    <div>
      <Components.Name />
      <Components.Type />
      <Components.Port />
      <Components.ApiKey />
      <Components.IsAssignableToProfile />
      <Components.IsEntitySpecific />
      <Components.Permissions />
      <Components.Status />
      <div className="buttons">
        <div className="left">
          {deleteQuery ? <DeleteButton onDelete={handleDelete} onFinally={onFinally} /> : null}
        </div>
        <div className="right">
          <CancelButton onCancel={onFinally} enabled />
          <SaveButton onCreate={handleCreate} onUpdate={handleUpdate} onFinally={onFinally} />
        </div>
      </div>
    </div>
  );
};
