import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem, getCachedItem } from 'connex-cds';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { InitializeApi } from './api/InitializeApi';
import { AppRoutes } from './AppRoutes';
import messages from './i18n';
import menuConfig from './menu-config';

API.setSource('admin'); // admin experience api.
API.setApiKey(getCachedItem('apiKey'), '');

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem
            messages={messages}
            initialTimeZone="America/Chicago"
            appId="admin"
            menuConfig={menuConfig}
          >
            <AppRoutes />
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
