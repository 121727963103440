import { http } from 'connex-cds';

export default {
  listAdminSKUs: () => http.get({ apiName: 'admin', path: '/admin/skus', useLoginToken: true }),
  getAdminSKU: ({ skuRef }) => http.get({ apiName: 'admin', path: `/admin/skus/${skuRef}`, useLoginToken: true }),
  createAdminSKU: sku => http.post({ apiName: 'admin', path: '/admin/skus', data: sku, useLoginToken: true }),
  deleteAdminSKU: ({ skuRef }) => http.delete({ apiName: 'admin', path: `/admin/skus/${skuRef}`, useLoginToken: true }),
  updateAdminSKU: sku =>
    http.patch({ apiName: 'admin', path: `/admin/skus/${sku.skuRef}`, data: sku, useLoginToken: true }),
};
