import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apps from '../apps';

export const useListApps = () => {
  return useQuery({
    queryKey: ['apps'],
    queryFn: () => apps.listApps(),
    staleTime: Infinity,
  });
};

export const useCreateApp = () => {
  const queryClient = useQueryClient();

  const queryKey = ['apps'];

  const mutation = useMutation({
    mutationFn: app => apps.createApp({ app }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useUpdateApp = () => {
  const queryClient = useQueryClient();

  const queryKey = ['apps'];

  const mutation = useMutation({
    mutationFn: apps.updateApp,
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useDeleteApp = () => {
  const queryClient = useQueryClient();

  const queryKey = ['apps'];

  const mutation = useMutation({
    mutationFn: appRef => apps.deleteApp({ appRef }),
    onMutate: async ({ appRef }) => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.filter?.(item => item.crn !== appRef);
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, appRef };
    },
    onError: (err, appRef, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};
