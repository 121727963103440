import { General } from './General';
import { Templates } from './templates/Templates';

export default {
  tabs: [
    {
      stringId: 'general',
      testId: 'general-tab',
      component: <General />,
    },
    {
      stringId: 'templates',
      testId: 'templates-tab',
      component: <Templates />,
    },
  ],
  translate: [{ getPath: 'stringId', setPath: 'name' }],
};
