import { Form } from 'connex-cds';
import React from 'react';
import { adminSKUs } from '../../api/query-hooks';
import { AdminSKUEditor } from './AdminSKUEditor';
import config from './adminSKUConfig';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const AdminSKUs = () => {
  const listQuery = adminSKUs.useListAdminSKUs();
  const updateQuery = adminSKUs.useUpdateAdminSKU();
  const createQuery = adminSKUs.useCreateAdminSKU();

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.VIEW}
      Editor={AdminSKUEditor}
      config={config}
      listQuery={listQuery}
      updateQuery={updateQuery}
      createQuery={createQuery}
    />
  );
};
