import { http } from 'connex-cds';

export default {
  listApps: () => http.get({ apiName: 'admin', path: `/apps?activeOnly=false` }),
  listActiveApps: () => http.get({ apiName: 'admin', path: `/apps` }),
  createApp: ({ app }) => http.post({ apiName: 'admin', path: `/apps`, data: app }),
  updateApp: app => http.patch({ apiName: 'admin', path: `/apps/${app.crn}`, data: app }),
  deleteApp: ({ appRef }) => http.delete({ apiName: 'admin', path: `/apps/${appRef}` }),
  createProfileAppConnection: ({ appRef, profileRef }) =>
    http.post({ apiName: 'admin', path: `/apps/${appRef}/connexions/profiles/${profileRef}` }),
};
