import { Form } from 'connex-cds';
import { FILTER_STRATEGY } from '../../for_sdk/filter-strategies';

export default {
  path: 'admin-skus',
  labelStringId: 'admin-skus',
  testId: 'admin-skus',
  keyPath: 'skuRef',
  fields: [
    { path: 'skuRef', labelStringId: 'skuRef', testId: 'skuRef', dataType: Form.FIELD_TYPES.string },
    { path: 'code', labelStringId: 'code', testId: 'code', dataType: Form.FIELD_TYPES.string },
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: Form.FIELD_TYPES.string },
    { path: 'description', labelStringId: 'description', testId: 'description', dataType: Form.FIELD_TYPES.string },
    { path: 'modules', labelStringId: 'modules', testId: 'modules', dataType: Form.FIELD_TYPES.array },
    {
      path: 'isAddOn',
      labelStringId: 'isAddOn',
      testId: 'isAddOn',
      dataType: Form.FIELD_TYPES.boolean,
      formatter: props => (props.row.isAddOn ? 'true' : 'false'),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'true') {
          return item === true;
        } else if (filterStrings[0] === 'false') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value=""></option>
            <option value="true">true</option>
            <option value="false">false</option>
          </select>
        );
      },
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: Form.FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
};
