import React from 'react';
import { ReactComponent as MixComponentsIcon } from '../../assets/mix-components-icon.svg';
import { ReactComponent as DriversIcon } from '../../assets/drivers-icon.svg';
import { ReactComponent as VehicleTypesIcon } from '../../assets/vehicle-types-icon.svg';
import { ReactComponent as OrderStatusesIcon } from '../../assets/order-statuses-icon.svg';
import { ReactComponent as ShippingMethodsIcon } from '../../assets/shipping-methods-icon.svg';
import SvgIcon from '@mui/material/SvgIcon';

const CustomIcons = {
  MixComponentsIcon: props => <SvgIcon inheritViewBox component={MixComponentsIcon} {...props} />,
  DriversIcon: props => <SvgIcon inheritViewBox component={DriversIcon} {...props} />,
  VehicleTypesIcon: props => <SvgIcon inheritViewBox component={VehicleTypesIcon} {...props} />,
  OrderStatusesIcon: props => <SvgIcon inheritViewBox component={OrderStatusesIcon} {...props} />,
  ShippingMethodsIcon: props => <SvgIcon inheritViewBox component={ShippingMethodsIcon} {...props} />,
};

export default CustomIcons;
