import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import templates from '../templates';

export const useListTemplates = appRef => {
  return useQuery({
    queryKey: ['templates', appRef],
    queryFn: () => templates.listTemplates(appRef),
    staleTime: Infinity,
  });
};

export const useCreateTemplate = appRef => {
  const queryClient = useQueryClient();

  const queryKey = ['templates', appRef];

  const mutation = useMutation({
    mutationFn: template => templates.createTemplate({ template, appRef }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};

export const useUpdateTemplate = appRef => {
  const queryClient = useQueryClient();

  const queryKey = ['templates', appRef];

  const mutation = useMutation({
    mutationFn: template => templates.updateTemplate({ template, appRef }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  return mutation.mutateAsync;
};
