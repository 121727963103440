import { Form, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div``;

export const General = ({ onCreate, onUpdate, onDelete, onFinally }) => {
  const { Components } = Form.useFormContext();

  const { SaveButton, CancelButton, DeleteButton } = Components;

  return (
    <Styled>
      <Layout.Row>
        <Layout.Column>
          <Components.LastName />
        </Layout.Column>
        <Layout.Column>
          <Components.FirstName />
        </Layout.Column>
      </Layout.Row>
      <Components.Password />
      <Components.Email />
      <Components.PhoneNumber />
      <div className="buttons">
        <div className="left">{onDelete ? <DeleteButton onDelete={onDelete} onFinally={onFinally} /> : null}</div>
        <div className="right">
          <CancelButton onCancel={onFinally} enabled />
          <SaveButton onCreate={onCreate} onUpdate={onUpdate} onFinally={onFinally} />
        </div>
      </div>
    </Styled>
  );
};
