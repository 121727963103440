import { Form } from 'connex-cds';
import { pick } from 'lodash';
import React from 'react';

export const General = ({ updateQuery, createQuery, deleteQuery, onFinally }) => {
  const { Components } = Form.useFormContext();

  const { SaveButton, CancelButton, DeleteButton } = Components;

  const handleUpdate = React.useCallback(
    adminSKU => {
      const pickedAdminSKU = {
        ...pick(adminSKU, ['code', 'skuRef', 'name', 'description', 'modules', 'isAddOn', 'status']),
        isAddOn: !!adminSKU.isAddOn,
      };
      return updateQuery?.(pickedAdminSKU);
    },
    [updateQuery]
  );

  const handleCreate = React.useCallback(
    adminSKU => {
      const pickedAdminSKU = {
        ...pick(adminSKU, ['code', 'skuRef', 'name', 'description', 'modules', 'isAddOn', 'status']),
        isAddOn: !!adminSKU.isAddOn,
      };
      return createQuery?.(pickedAdminSKU);
    },
    [createQuery]
  );

  const handleDelete = React.useCallback(() => {}, []);

  return (
    <div>
      <Components.Code />
      <Components.SkuRef />
      <Components.Name />
      <Components.Description />
      <Components.Modules />
      <Components.IsAddOn />
      <div className="buttons">
        <div className="left">
          {deleteQuery ? <DeleteButton onDelete={handleDelete} onFinally={onFinally} /> : null}
        </div>
        <div className="right">
          <CancelButton onCancel={onFinally} enabled />
          <SaveButton onCreate={handleCreate} onUpdate={handleUpdate} onFinally={onFinally} />
        </div>
      </div>
    </div>
  );
};
