import React from 'react';
import { useUserContext } from './components/user';
import { useEntityContext } from './util/entity-provider/context';

export const FullStoryIdentify = () => {
  const { user } = useUserContext();
  // TODO: entityName is undefined.  Need to figure out why.
  const { entityName, entityRef } = useEntityContext();

  React.useEffect(() => {
    if (user?.profileRef) {
      const displayName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || undefined;
      const payload = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        entityName,
        entityRef,
        displayName,
        isPlatformAdmin: user?.isPlatformAdmin,
        isPlatformSupport: user?.isPlatformSupport,
      };
      window?.FS?.identify?.(user?.profileRef, payload);
    }
  }, [
    entityName,
    entityRef,
    user?.firstName,
    user?.isPlatformAdmin,
    user?.isPlatformSupport,
    user?.lastName,
    user?.profileRef,
  ]);
};
